<template>
    <b-container fluid="">
        <b-card no-body class="p-1 mb-1">
            <b-row>
                <b-col cols="3">
                    <b-button @click="toCreate()" variant="success">
                        <b-icon-plus scale="1.3"/>
                    </b-button>
                </b-col>
                <b-col cols="9" class="text-right">
                    <b-button @click="applyFilters" variant="primary">
                        <b-icon-arrow-repeat scale="1.2"/>
                    </b-button>
                    <b-button v-b-toggle.filters variant="light"><b-icon-filter/>Фильтры</b-button>
                    <small v-if="filtersExists">
                        <b-link @click.prevent="resetFilters">(сбросить)</b-link>
                    </small>
                </b-col>
            </b-row>

            <b-collapse id="filters">
                <b-card-body>
                    <b-row>
                        <b-col md="3">
                            <b-form-group description="Дата (от)">
                                <datetime @input="clearPage()" type="datetime" class="form-control no-border" v-model="filters.dateFrom"></datetime>
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group description="Дата (до)">
                                <datetime @input="clearPage()" type="datetime" class="form-control no-border" v-model="filters.dateTo"></datetime>
                            </b-form-group>
                        </b-col>
                        <b-col md="2">
                            <b-form-group description="Отображать только акты, требующие приемки">
                                <b-checkbox @input="clearPage()" v-model="filters.notReceivedOnly">Непринятые</b-checkbox>
                            </b-form-group>
                        </b-col>
                        <b-col md="2">
                            <b-form-group>
                                <b-checkbox @input="clearPage()" v-model="filters.basedOnQualityControlActsOnly">Созданные на основе актов контроля качества</b-checkbox>
                            </b-form-group>
                        </b-col>
                        <b-col md="2">
                            <b-form-group description="Отображать только отмененные акты">
                                <b-checkbox @input="clearPage()" v-model="filters.canceledOnly">Отмененные</b-checkbox>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <div class="row">
                        <div class="col-md-4">
                            <b-form-group description="Откуда">
                                <storage-picker @input="clearPage()" v-model="filters.storageFrom" :nullable="true"></storage-picker>
                            </b-form-group>
                        </div>
                        <div class="col-md-4">
                            <b-form-group description="Куда">
                                <storage-picker :allowed-roles="null" @input="clearPage()" v-model="filters.storageTo" :nullable="true"></storage-picker>
                            </b-form-group>
                        </div>
                        <div class="col-md-4">
                            <b-form-group description="Поиск по номенклатуре">
                                <b-form-input v-model="filters.nomenclatureName" @input="clearPage()" debounce="500" />
                            </b-form-group>
                        </div>
                    </div>
                </b-card-body>
            </b-collapse>
        </b-card>
        <b-table
            hover
            head-variant="dark"
            :fields="fields"
            :items="items"
            @row-dblclicked="toForm"
            responsive
        >
            <template #cell(actions)="row">
                <b-button-group>
                    <b-button
                        @click="toForm(row.item)"
                        size="sm"
                        variant="warning"
                    >
                        <b-icon-pencil-square/>
                    </b-button>
                    <b-button
                        v-show="!row.item.canceled"
                        @click="cancel(row.item)"
                        size="sm"
                        variant="danger"
                    >
                        <b-icon-trash-fill/>
                    </b-button>
                    <b-button
                        v-if="row.item.canceled"
                        @click="cancel(row.item)"
                        size="sm"
                        variant="light"
                    >
                        <b-icon-arrow-return-left/>
                    </b-button>
                </b-button-group>
            </template>
        </b-table>

        <b-row>
            <b-col md="6">
                <span>
                    Показано с {{ meta.current_page * meta.per_page - meta.per_page + 1 }}
                    по {{ meta.current_page * meta.per_page - meta.per_page + items.length }}
                    из {{ meta.total_rows }} записей
                </span>
            </b-col>
            <b-col md="6">
                <b-pagination
                    v-model="meta.current_page"
                    :total-rows="meta.total_rows"
                    :per-page="meta.per_page"
                    @input="applyFilters"
                    class="justify-content-end"
                />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import {debounce} from 'lodash'
    import { MATERIAL_TRANSFER_ACT_ENDPOINT } from "@utils/endpoints";
    import moment from 'moment'
    import lsfMixin from '../../utils/localStorageFilterMixin'
    import { Datetime } from 'vue-datetime';
    import StoragePicker from '../_common/StoragePicker';

    const FILTER_PATTERN = {
        page: null,
        dateTo: null,
        dateFrom: null,
        storageTo: null,
        storageFrom: null,
        notReceivedOnly: true,
        notCanceledOnly: false,
        nomenclatureName: '',
    };

    export default {
        name: 'MaterialTransferList',
        components: {
            datetime: Datetime,
            StoragePicker,
        },
        mixins: [lsfMixin],
        created() {
            this.initFilters(this, FILTER_PATTERN);
        },
        watch: {
            searchString: debounce(function() {
                this.updateNomenclatureName();
            }, 250),
            'filters': {
                handler: function () {
                    this.watchFilters(this.filters);
                },
                deep: true,
            },
        },
        data() {
            return {
                searchString: '',
                filters: {
                    page: null,
                    dateTo: null,
                    dateFrom: null,
                    storageTo: null,
                    storageFrom: null,
                    notReceivedOnly: true,
                    notCanceledOnly: false,
                    basedOnQualityControlActsOnly: false,
                    canceledOnly: false,
                    nomenclatureName: '',
                },
                loading: false,
                fields: [
                    {
                        key: 'id',
                        label: '№',
                    },
                    {
                        key: 'date',
                        label: 'Дата',
                        formatter(value) {
                            return value ? moment(value).format('DD.MM.YYYY HH:mm') : '';
                        },
                    },
                    {
                        key: 'preview.name',
                        label: 'Номенклатура',
                    },
                    {
                        key: 'storageFrom.name',
                        label: 'Откуда',
                    },
                    {
                        key: 'storageTo.name',
                        label: 'Куда',
                    },
                    {
                        key: 'actions',
                        label: 'Действия',
                    },
                ],
                items: [],
                meta: {
                    current_page: 1,
                    total_rows: 0,
                    per_page: 25,
                },
            }
        },
        methods: {
            moment,
            updateNomenclatureName() {
                this.filters.nomenclatureName = this.searchString;
            },
            clearPage() {
                this.filters.page = 1;
            },
            toCreate() {
                this.$router.push({name: 'MaterialTransferActCreate'});
            },
            toForm(act) {
                this.$router.push({name: 'MaterialTransferActUpdate', params: {id: act.id}});
            },
            cancel(act) {
                this.$bvModal.msgBoxConfirm('Вы уверены, что хотите удалить акт перемещения?', {
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Да',
                    cancelTitle: 'Нет',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value === true) {
                        this.$http.put(MATERIAL_TRANSFER_ACT_ENDPOINT + `/${act.id}/cancel`, {
                            canceled: !act.canceled
                        }).then(() => {
                            act.canceled = !act.canceled;
                        }).catch(this.$root.responseError);
                    }
                });
            },
            applyFilters() {
                let params = {
                    with: [
                        'storageFrom',
                        'storageTo',
                        'previews',
                    ],
                    filters: {},
                };
                if (this.filters.dateFrom) {
                    params['filters[dateFrom]'] = moment(this.filters.dateFrom).format('YYYY-MM-DD HH:mm');
                }

                if (this.filters.dateTo) {
                    params['filters[dateTo]'] = moment(this.filters.dateTo).format('YYYY-MM-DD HH:mm');
                }

                if (this.filters.storageFrom) {
                    params['filters[storage_from_ids][]'] = this.filters.storageFrom.id;
                }

                if (this.filters.storageTo) {
                    params['filters[storage_to_ids][]'] = this.filters.storageTo.id;
                }

                if (this.filters.notReceivedOnly) {
                    params.filters.not_received_only = 1;
                }

                if (this.filters.notCanceledOnly) {
                    params['filters[canceled]'] = 0;
                }

                if (this.filters.nomenclatureName) {
                    params['filters[nomenclatureName]'] = this.filters.nomenclatureName;
                }

                if (this.meta.current_page) {
                    params['page'] = this.meta.current_page;
                }

                if (this.filters.basedOnQualityControlActsOnly) {
                    params['filters[based_on_qc_acts]'] = 1;
                }

                if (this.filters.canceledOnly) {
                    params.filters.canceled = true;
                }

                this.$http.get(MATERIAL_TRANSFER_ACT_ENDPOINT, {
                    params: params
                }).then(response => {
                    this.items = response.data.data;

                    this.meta.current_page = response.data.current_page;
                    this.meta.per_page = response.data.per_page;
                    this.meta.total_rows = response.data.total;
                }).catch(error => {
                    this.$root.responseError(error, 'Ошибка!')
                });
            },
        }
    }
</script>
