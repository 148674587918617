const LOCAL_STORAGE_FILTER_PREFIX = 'LS_FILTER_';

/**
 * Класс для управления фильтрами в LocalStorage
 */
class LocalStorageFilter {
    constructor() {
        this.__name = null;
        this.__pattern = null;
    }

    /**
     * Инициализируем фильтры
     *
     * @param object
     * @param pattern
     * @param name
     */
    init( object, pattern, name = null ) {
        this.__pattern = pattern;
        this.__name = LOCAL_STORAGE_FILTER_PREFIX + object.$options.name + ( name ? '_' + name : '' );

        if ( !this.get() ) {
            this.reset();
        }
    }

    /**
     * Получаем фильтры
     */
    get() {
        let filters = localStorage.getItem( this.__name );
        return filters !== 'undefined' && filters !== null ? JSON.parse( filters ) : null;
    }

    /**
     * Сбрасываем фильтры на дефолтное значение
     */
    reset() {
        this.set( this.__pattern );
    }

    /**
     * Проверяем на то, что фильтры установлены
     */
    exists() {
        let filters = this.get();

        let serializedFilters = JSON.stringify( filters );
        let serializedFiltersPattern = JSON.stringify( this.__pattern );

        return serializedFilters !== serializedFiltersPattern;
    }

    /**
     * Устанавливаем фильтры
     *
     * @param filters
     */
    set( filters ) {
        let serializedFilters = JSON.stringify( filters );
        localStorage.setItem( this.__name, serializedFilters );
    }

    /**
     * Сравниваем фильтры
     *
     * @param filters
     */
    compare( filters ) {
        return JSON.stringify( this.get() ) === JSON.stringify( filters );
    }
}

export { LOCAL_STORAGE_FILTER_PREFIX };
export default new LocalStorageFilter();
