import lsf from './localStorageFilter';

/**
 * Добавляет методы для работы с фильтрами
 * реализует реактивность и сохранение данных в localStorage
 *
 * @type {{
 *      data(): *,
 *      methods: {
 *          applyFilters(),
 *          resetFilters(): void,
 *          setFilters(*=): void,
 *          updateFilters(): void,
 *          initFilters(*=, *=): void
 *      }
 * }}
 */
let lsfMixin = {
    data() {
        return {
            lsf,
            filters: null,
            filtersParameters: {
                exists: false,
            },
            needApplyFilters: true,
        }
    },

    computed: {
        /**
         * Получаем фильтры
         *
         * @returns {*}
         */
        getFilters() {
            return this.filters;
        },

        /**
         * Фильтры используются?
         *
         * @returns {*}
         */
        filtersExists() {
            return this.filtersParameters.exists;
        }
    },

    methods: {
        /**
         * Этот метод переопределяется в компоненте
         * и реализует ту последовательность действий
         * которая необходима после применения фильтров
         */
        applyFilters() {},

        /**
         * Сбрасывает фиьтры на значения по умолчанию
         */
        resetFilters() {
            this.lsf.reset();
            this.updateFilters();
        },

        /**
         * Обновляет значение фильтров и применяет их
         */
        updateFilters() {
            this.filters = this.lsf.get();
            this.filtersParameters.exists = this.lsf.exists();

            if (this.needApplyFilters) {
                this.applyFilters();
            }
        },

        /**
         * Устанавливает значения фильтров
         *
         * @param filters
         */
        setFilters( filters ) {
            this.lsf.set( filters );
            this.updateFilters();
        },

        /**
         * Обновление при изменении фильтров
         *
         * @param filters
         */
        watchFilters( filters ) {
            if ( !this.compareFilters( filters ) ) {
                this.setFilters( filters );
            }
        },

        /**
         * Сравнение фильтров
         *
         * @param filters
         */
        compareFilters( filters ) {
            return this.lsf.compare( filters );
        },

        /**
         * Инициализация фильтров
         *
         * @param object
         * @param pattern
         * @param name
         * @param apply
         */
        initFilters( object, pattern, apply = true, name = null ) {
            this.lsf.init( object, pattern, name );

            if ( apply ) {
                this.updateFilters();
            }
        }
    }
};

export default lsfMixin;
